import { User } from "@/types";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/Avatar";
import { Badge } from "./ui/Badge";
import { Card, CardContent, CardFooter, CardHeader } from "./ui/Card";
import { useTranslation } from "react-i18next";
type CourseCardProps = {
    cover: string;
    title: string;
    description: string;
    user?: User | null;
};
function CourseCard({ cover, description, title, user }: CourseCardProps) {
    const { t } = useTranslation();
    return (
        <Card className="w-full rounded-lg">
            <CardHeader className="flex flex-row items-center justify-between p-0 pb-2 space-y-0">
                <img
                    src={cover}
                    alt="course cover"
                    className="w-full h-[10rem]  object-cover rounded-t-lg"
                    onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        target.onerror = null; // Prevents infinite loop if placeholder fails
                        target.src =
                            "https://placehold.co/600x400?text=Course+Cover";
                    }}
                />
            </CardHeader>
            <CardContent className="p-2 pb-4">
                <div className="flex items-center justify-between gap-2 text-sm">
                    <Badge className="flex-1 text-sm truncate bg-primary/10 text-primary hover:text-white max-w-fit">
                        {title}
                    </Badge>
                    {/* <Badge>4.5 ⭐</Badge> */}
                </div>
                <p className="w-full mt-2 text-sm md:text-xs lg:text-sm line-clamp-3">
                    {description}
                </p>
            </CardContent>
            <CardFooter>
                <div className="flex items-center gap-2">
                    <Avatar>
                        <AvatarImage
                            src={
                                user?.avatar_url ||
                                "https://avatar.iran.liara.run/public/job/teacher/male"
                            }
                            alt="instructor avatar"
                        />
                        <AvatarFallback>CN</AvatarFallback>
                    </Avatar>
                    {user ? (
                        <div className="flex flex-col">
                            <span className="text-xs font-bold">
                                {user.first_name} {user.last_name}
                            </span>
                            <span className="text-xs text-muted-foreground">
                                {user.education}
                            </span>
                        </div>
                    ) : (
                        <div className="flex flex-col">
                            <span className="text-xs font-bold">
                                {t("app.noData")}
                            </span>
                            <span className="text-xs text-muted-foreground">
                                {t("app.noData")}
                            </span>
                        </div>
                    )}
                </div>
            </CardFooter>
        </Card>
    );
}

export default CourseCard;
